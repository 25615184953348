(() => {
    const WcSizeGuide = {

        init: function() {

            this.isWcProductPage = document.querySelector('body.single-product');
            this.sizeGuideWindow = document.querySelector('.modal-size-guide-section');
            this.sizeGuideBtnOpen = document.querySelector('.size-guide');
            this.sizeGuideBtnClose = document.querySelector('.modal-size-guide-close');

            if (!this.isWcProductPage || !this.sizeGuideWindow) { return; }

            this.setUp();

        },

        setUp: function() {
            this.sizeGuideBtnOpen.classList.add('show');
            this.sizeGuideBtnOpen.addEventListener('click', this.openModalWindow.bind(WcSizeGuide), {once: true});
            document.addEventListener('click', this.eventClickOutsideWindow.bind(WcSizeGuide), {once: true});
            document.addEventListener('keydown', this.eventKeydownEscape.bind(WcSizeGuide), {once: true});
            this.sizeGuideBtnClose.addEventListener('click', this.eventClickBtnClose.bind(WcSizeGuide), {once: true});
        },
      
        eventClickOutsideWindow: function(e) {
            if(!this.sizeGuideWindow.contains(e.target) && 
            !e.target.classList.contains('size-guide') && 
            this.sizeGuideWindow.classList.contains('show')) {
                this.closeModalWindow();
            }
        },

        eventKeydownEscape: function(e) {
            if(e.key === "Escape" && this.sizeGuideWindow.classList.contains('show')) {
                this.closeModalWindow();
            }
            return;
        },

        eventClickBtnClose: function(e) {
            e.preventDefault();
            this.closeModalWindow();
            return;
        },

        closeModalWindow: function() {
            this.sizeGuideWindow.classList.remove('show');
            this.isWcProductPage.classList.remove('overflow-block');
        },

        openModalWindow: function(e) {
            e.preventDefault();
            if(!this.sizeGuideWindow.classList.contains('show')){
                this.sizeGuideWindow.classList.add('show');
                this.isWcProductPage.classList.add('overflow-block');
            } else {this.closeModalWindow();}
        },

    }

    WcSizeGuide.init();
})();