// woocommerce qty btn
	var timeout;
	jQuery( function( $ ) {
			$('body').on('click', '.minus', function (e) {
			var $inputQty = $(this).parent().find('input.qty');
			var val = parseInt($inputQty.val());
			var step = $inputQty.attr('step');
			step = 'undefined' !== typeof(step) ? parseInt(step) : 1;
			if (val > 1) {
				$inputQty.val(val - step).change();
			} else {
				$inputQty[0].value = 1;
			}
		});
	
		$('body').on('click', '.plus', function (e) {
			var $inputQty = $(this).parent().find('input.qty');
			var val = parseInt($inputQty.val());
			var step = $inputQty.attr('step');
			step = 'undefined' !== typeof(step) ? parseInt(step) : 1;
			$inputQty.val(val + step).change();
		});
	
		$('.woocommerce').on('change', 'input.qty', function(){
			if ( timeout !== undefined ) {
				clearTimeout( timeout );
			}
			timeout = setTimeout(function() {
				$("[name='update_cart']").trigger("click");
			}, 300 ); 
		});
		} );