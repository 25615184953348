// Transfer language switcher in menu
const transferLanguageSwitcher = () => {
    const windowInnerWidth = window.innerWidth;
    if(document.querySelector('.trp_language_switcher_shortcode')) {
        const languageSwitcher = document.querySelector('.trp_language_switcher_shortcode');
        const createElem = () => {
        if(!document.querySelector('.menu-item.language-switcher') && windowInnerWidth <= 576) {
                const navbarListWrapper = document.querySelector('header .navbar-nav');
                const navbarItem = document.createElement('li');
                navbarItem.className = "menu-item language-switcher";
                navbarItem.append(languageSwitcher);
                navbarListWrapper.append(navbarItem);
            }
        }
        const removeElem = () => {
            if(document.querySelector('.menu-item.language-switcher')) {
                document.querySelector('.lang_switcher').append(languageSwitcher);
                document.querySelector('.menu-item.language-switcher').remove();
            }
        }
        if(windowInnerWidth <= 576) {
            createElem();
        } else {
            removeElem();
        }
    }
}
window.addEventListener('resize', () => {
    transferLanguageSwitcher();
});
document.addEventListener('DOMContentLoaded', () => {
    transferLanguageSwitcher();
});
// Drop down MyAccount menu
const dropDownMenu = (DropDownTriggerSelector, selectorWrapper) => {
    if(document.querySelector(DropDownTriggerSelector) && document.querySelector(selectorWrapper)) {
        const dropDownTriggerElem = document.querySelector(DropDownTriggerSelector);
        const selectorWrapperElem = document.querySelector(selectorWrapper);
        const menuListItem = selectorWrapperElem.querySelectorAll('li');
        const show = () => selectorWrapperElem.classList.add('show');
        const hide = () => selectorWrapperElem.classList.remove('show');
        const toggleElem = () => selectorWrapperElem.classList.contains('show') ? hide() : show();
        dropDownTriggerElem.addEventListener('click', toggleElem);
        let isActiveElemMenu = false;
        menuListItem.forEach((item) => {
            item.classList.contains('is-active')
            ? (selectorWrapperElem.prepend(selectorWrapperElem.querySelector('.is-active')), isActiveElemMenu = true)
            : '';
        });
        isActiveElemMenu ? '' : (show(), dropDownTriggerElem.removeEventListener('click', toggleElem));
    }
}
dropDownMenu('.nitg-MyAccount-navigation-dropdown', '.woocommerce-MyAccount-navigation > ul');

// not linck item active menu contains hash linck
const currentItemMenuContainHash = () => {
    const hashLinck = window.location.hash;
    if(!Boolean(hashLinck)) {
        if(document.querySelectorAll('.current-menu-item').length > 0) {
            const lincksMenu = document.querySelectorAll('.current-menu-item');
            lincksMenu.forEach((item) => {
                const hrefLinck = item.querySelector('a').getAttribute('href').split('/')[1];
                if(hrefLinck.includes('#') && !window.location.href.includes(hrefLinck)) {
                    item.classList.remove('active');
                }
            });
        }
    }
}
currentItemMenuContainHash();

// Remove quanty label when cart empty

const removeLabelEmptyCart = () => {
    if(document.querySelector('.header-cart-widget > .cart-contents')) {
        const miniCart = document.querySelector('.header-cart-widget > .cart-contents');
        const miniCartQuanty = Number(miniCart.innerText);
        if( miniCartQuanty === 0) {
            miniCart.classList.add('empty');
        } else {
            miniCart.classList.contains('empty') && miniCartQuanty === 0 ? miniCart.classList.remove('emty') : false;
        }
    }
}
removeLabelEmptyCart();

// Fix bug jQuery (touchstart & touchmove)
(function () {
    if (typeof EventTarget !== 'undefined') {
      let supportsPassive = false;
      try {
        const opts = Object.defineProperty({}, 'passive', {
          get: () => {
            supportsPassive = true;
          },
        });
        window.addEventListener('testPassive', null, opts);
        window.removeEventListener('testPassive', null, opts);
      } catch (e) {}
      const func = EventTarget.prototype.addEventListener;
      EventTarget.prototype.addEventListener = function (type, fn) {
        this.func = func;
        this.func(type, fn, supportsPassive ? { passive: false } : false);
      };
    }
  })();

  // Shiping add selector
  const shipingCartStyle = () => {
    if(document.querySelector(".woocommerce-shipping-totals #shipping_method")) {
        document.querySelector(".woocommerce-shipping-totals [data-title='Versand']").classList.add('shipping-yes');
    }
  }
  shipingCartStyle();

  //  Back history in cart
 const backToHistory = () => {
    if(document.querySelector('.back-btn')) {
        const backBtn = document.querySelector('.back-btn');
        backBtn.addEventListener('click', () => window.history.back(), {once : true});
    }
 }
 backToHistory();

 // My account logged in user
$('.header .dropdown > a').click(function() {
    if (!$(this).hasClass("clicked")) {
        $(this).addClass("clicked");
    } else {
        location.href = this.href;
    }
});
